import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { managerClient } from '../../api/manager-client';
import { booking, clubs } from '../../api/api-requests';
import { functions } from '../../api/firebase';
import { httpsCallable } from 'firebase/functions';
import { getManagerAvailableTime } from '../../services/booking';
import { showErrorAlert, showSuccessAlert } from '../../utils/toastMessages';
import { navigate } from 'gatsby';

const createOrderAndPaymentIntent = httpsCallable(
  functions,
  'createOrderAndPaymentIntent'
);

let initialStates = {
  isLoading: false,
  availableProducts: {},
  availableSlots: {},
  showBookingModal: false,
  selectedProduct: {},
  bookingDetail: null,
  userInfo: { name: '', email: '' },
  userType: 'LOGIN',
  paymentIntent: null,
  playerEmails: [],
  selectedOntee: null,
  shareBookingDetailInfo: null,
  clubProfileData: null,
  selectedDate: moment().format('YYYY-MM-DD'),
};

export const onteeConfirmPayment = createAsyncThunk(
  'booking/confirmPayment',
  async (thunkAPI, { dispatch }) => {
    try {
      const { onteeInfo, userObj } = thunkAPI;

      let temp = [...onteeInfo.selectedSlot.rates];

      onteeInfo.selectedSlot.rates.map((item, index) => {
        let tempValue = { ...temp[index] };
        if (tempValue.name == '1 greenfee') {
          tempValue.nrPlayers = 1;
          temp[0] = tempValue;
        }
      });

      let bookingPayload = {
        booking: {
          golfCourseId: onteeInfo?.clubInfo?.objectID,
          startTime: onteeInfo?.selectedSlot?.dateTime,
          nrPlayers: 1,
          rates: temp,
        },
      };
      let billingDetails = {
        email: userObj?.email,
      };

      // Create a booking and try to charge the selected payment method for it

      createOrderAndPaymentIntent({
        booking: bookingPayload,
        email: userObj?.email,
        location: onteeInfo?.clubInfo?.name,
        authNotRequire: true,
      }).then((res) => {
        dispatch(
          setPaymentIntent({
            clientSecret: res?.data?.result?.clientSecret,
            billingDetails,
            onteeInfo,
            userInfo: userObj,
            type: 'ontee',
          })
        );
      });
    } catch (err) {
      console.log('createOrderAndPaymentIntent ERR', err);

      // this.cancelOnteeBooking() ???
    }
  }
);

export const confirmPayment = createAsyncThunk(
  'booking/confirmPayment',
  async (thunkAPI, { dispatch }) => {
    try {
      dispatch(setisLoading(true));
      const { productInfo, clubInfo, userObj } = thunkAPI;
      const { price, currency, id, dateTime, endTime, clubId } = productInfo;

      const { clubName, clubLocations } = clubInfo;

      let bookingPayload = {
        booking: {
          golfCourseId: clubId,
          startTime: dateTime,
          nrPlayers: 1,
          ...clubInfo,

          ...productInfo,
        },
      };
      let billingDetails = {
        email: userObj?.email,
      };

      // Create a booking and try to charge the selected payment method for it

      createOrderAndPaymentIntent({
        booking: bookingPayload.booking,
        email: userObj?.email,
        name: userObj?.name,
        location: clubName,
        managerProduct: true,
        amount: price,
        currency: currency?.name,
        productId: id,
        authNotRequire: true,
      }).then((res) => {
        if (res.data.success) {
          dispatch(
            setPaymentIntent({
              clientSecret: res?.data?.result?.clientSecret,
              billingDetails,
              bookingDetail: {
                productId: id,
                amount: parseInt(price),
                currency: currency?.name,
                clubCountry: clubLocations?.code,
                userId: userObj?.id || 'GUEST',
              },
              type: 'manager-product',
              booking: bookingPayload?.booking,
              clubId,
              userObj,
            })
          );
        } else {
          let errorCode = res.data.error?.code;
          switch (errorCode) {
            case 'amount_too_small':
              showErrorAlert(`Amount is too small ${price} ${currency?.name}`);

              break;
            case 'email_invalid':
              showErrorAlert(`Invalid Email`);

              break;
            default:
              showErrorAlert('Something went wrong try again later');
          }
          dispatch(setisLoading(false));
        }
      });
    } catch (err) {
      console.log('createOrderAndPaymentIntent ERR', err);
      showErrorAlert('Something went wrong try again later');

      dispatch(setisLoading(false));
      // this.cancelOnteeBooking() ???
    }
  }
);

export const createBooking = createAsyncThunk(
  'booking/createBooking',
  async (thunkAPI, { dispatch }) => {
    try {
      // Create a booking and try to charge the selected payment method for it

      let response = await managerClient(booking.createBooking(thunkAPI));

      if (response.status == 200) {
        dispatch(setShowBookingModal(false));
        dispatch(setBookingDetail(thunkAPI?.body?.booking));
        const { emails, body } = thunkAPI;
        let payload = {
          emails,
          bookingDetails: body,
          sendEmailToOwner: true,
        };
        dispatch(
          setShareBookingDetailEmailInfo({
            bookingDetails: payload.bookingDetails,
          })
        );
        dispatch(setisLoading(false));
        emails.length > 0 && dispatch(sendBookingEmails({ payload }));
        navigate('/bookings');
      }
    } catch (err) {
      dispatch(setisLoading(false));
      showErrorAlert(`Something went wrong.`);
      console.log('createOrderAndPaymentIntent ERR', err);

      // this.cancelOnteeBooking() ???
    }
  }
);

export const getAvailableSlots = createAsyncThunk(
  'booking/availableSlots',
  async (thunkAPI, { dispatch }) => {
    try {
      let promise = [];
      thunkAPI.data.map((item) => {
        if (item.type == 'manager-location') {
          promise.push(
            getManagerAvailableTime({
              id: item?.objectID,
              productId: false,
              date: moment(thunkAPI.date).format('YYYY-MM-DD'),
            })
          );
        }
      });

      let data = await Promise.all(promise);
      let availableSlots = {};
      data.map((slot) => {
        availableSlots[slot.locationId] = slot;
      });

      dispatch(setAvailableSlots({ ...availableSlots }));
    } catch (err) {
      showErrorAlert(`Something went wrong.`);
      console.log('err', err);
    }
  }
);

export const getAvailableProducts = createAsyncThunk(
  'booking/availableProducts',
  async (thunkAPI, { dispatch }) => {
    try {
      const { clubId, date } = thunkAPI;
      let res = await managerClient(
        booking.getAvailableProducts({ clubId, date })
      );
      if (res.status == 200) {
        dispatch(setAvailableProducts({ clubId, data: res?.data?.data }));
        // return { locationId: id, availableTime: res.data.data };
      }
    } catch (err) {
      showErrorAlert(`Something went wrong.`);
      console.log('err', err);
    }
  }
);

export const sendBookingEmails = createAsyncThunk(
  'booking/sendBookingEmails',
  async (thunkAPI, { dispatch }) => {
    try {
      dispatch(setisLoading(true));
      let res = await managerClient(
        booking.sendBookingEmail(thunkAPI?.payload)
      );
      // showSuccessAlert("Email sent successfully");
      if (res?.data?.success) {
        thunkAPI?.showSuccessAlert &&
          showSuccessAlert('Email sent successfully');
      }
      dispatch(setisLoading(false));
    } catch (err) {
      dispatch(setisLoading(false));
      console.log('err', err);
    }
  }
);

export const getClubInfo = createAsyncThunk(
  'clubs/clubProfileData',
  async (thunkAPI, { dispatch }) => {
    try {
      const res = await managerClient(
        clubs.getClub({
          params: { clubId: thunkAPI?.clubId },
          body: { userId: thunkAPI?.userId },
        })
      );
      if (res?.data?.success) {
        dispatch(
          setClubProfileData({
            clubId: thunkAPI?.clubId,
            ...res?.data?.data,
            clubGeoLoc: thunkAPI?.clubGeoLoc,
          })
        );
      }
    } catch (error) {
      console.log(error);
      navigate('/search/');
    }
  }
);

export const bookingSlice = createSlice({
  name: 'booking',
  initialState: {
    ...initialStates,
  },

  reducers: {
    setisLoading: (state, action) => {
      state.isLoading = action.payload;
    },

    setAvailableProducts: (state, action) => {
      let { clubId, data } = action.payload;
      state.availableProducts = {
        ...state.availableProducts,
        [clubId]: data,
      };
    },
    setAvailableSlots: (state, action) => {
      state.availableSlots = {
        ...state.availableSlots,
        ...action.payload,
      };
    },
    setUserInfo: (state, action) => {
      const { key, value } = action.payload;
      state.userInfo = { ...state.userInfo, [key]: value };
    },
    resetUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setShowBookingModal: (state, action) => {
      state.showBookingModal = action.payload;
    },

    setSelectedProduct: (state, action) => {
      state.selectedProduct = { ...state.selectedProduct, ...action.payload };
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    setPaymentIntent: (state, action) => {
      state.paymentIntent = action.payload;
    },
    setBookingDetail: (state, action) => {
      state.bookingDetail = action.payload;
    },
    removeInfo: () => initialStates,
    addPlayerEmails: (state, action) => {
      state.playerEmails = action.payload;
    },
    setShareBookingDetailEmailInfo: (state, action) => {
      state.shareBookingDetailInfo = action.payload;
    },
    setSelectedOntee: (state, action) => {
      state.selectedOntee = action.payload;
    },
    setClubProfileData: (state, action) => {
      state.clubProfileData = { ...action.payload };
    },
    changeSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    },
    changeIsFavourite: (state, action) => {
      state.clubProfileData = {
        ...state.clubProfileData,
        isFavourite: action.payload,
      };
    },
  },
});

export const {
  setisLoading,
  setAvailableProducts,
  setAvailableSlots,
  setShowBookingModal,
  setSelectedProduct,
  setUserInfo,
  setUserType,
  setPaymentIntent,
  setBookingDetail,
  removeInfo,
  addPlayerEmails,
  setSelectedOntee,
  resetUserInfo,
  setShareBookingDetailEmailInfo,
  setClubProfileData,
  changeSelectedDate,
  changeIsFavourite,
} = bookingSlice.actions;

export default bookingSlice.reducer;
