import React, { useEffect } from 'react';
import './index.css';

const TopLoader = (props) => {
  return (
    <div className="bg-loader">
      <div className="inside-line" />
    </div>
  );
};

export default TopLoader;
