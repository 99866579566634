import { createSlice } from '@reduxjs/toolkit';

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    loginModalOpen: false,
  },
  reducers: {
    setLoginModalOpen: (state, action) => {
      state.loginModalOpen = action.payload;
    },
  },
});

export const { setLoginModalOpen } = loadingSlice.actions;

export default loadingSlice.reducer;
