import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext({
  user: null,
  setUser: () => {},
  checked: false,
  setChecked: () => {},
  isRedirectURL: true,
  setIsRedirectURL: () => {},
});

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [checked, setChecked] = useState(false);
  const [isRedirectURL, setIsRedirectURL] = useState(true);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        checked,
        setChecked,
        isRedirectURL,
        setIsRedirectURL,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
