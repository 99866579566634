import axios from 'axios';

// Init axios client

const baseURL =
  process.env.NODE_ENV === 'development'
    ? 'http://127.0.0.1:5002/racqy-manager-internal/europe-west1/racqyWebPlatformApi'
    : 'https://europe-west1-racqy-manager-internal.cloudfunctions.net/racqyWebPlatformApi';

const instance = axios.create({
  baseURL,
  // timeout: 1000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const managerClient = instance;
