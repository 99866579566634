import { managerClient } from '../api/manager-client';

import { booking } from '../api/api-requests';

import moment from 'moment';

const getManagerAvailableTime = async ({ id, date, productId }) => {
  try {
    let res = await managerClient(
      booking.getAvailableSlots({
        id,
        productId,
        date: moment(date).format('YYYY-MM-DD'),
      })
    );
    if (res.status == 200) {
      return { locationId: id, availableTime: res.data.data };
    }
  } catch (err) {
    console.log('err', err);
    throw err;
  }
};

export { getManagerAvailableTime };
