import { createSlice } from '@reduxjs/toolkit';

let initialStates = {
  ontees: [],
};

export const onteesSlice = createSlice({
  name: 'ontees',
  initialState: {
    ...initialStates,
  },

  reducers: {
    setOntees: (state, action) => {
      state.ontees = action.payload;
    },
  },
});

export const { setOntees } = onteesSlice.actions;

export default onteesSlice.reducer;
