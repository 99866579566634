const commonHeaders = {
  'Content-Type': 'application/json',
};

export const authentication = {
  phoneVerification: (body) => ({
    method: 'POST',
    url: 'v1/auth/phoneverification',
    data: body,
    headers: commonHeaders,
  }),

  verifyPhoneCode: (body) => ({
    method: 'POST',
    url: 'v1/auth/phoneverification/verify',
    data: body,
    headers: commonHeaders,
  }),
  verifyEmailCode: (body) => ({
    method: 'POST',
    url: 'v1/auth/emailverification',
    data: body,
    headers: commonHeaders,
  }),
  emailVerification: (body) => ({
    method: 'POST',
    url: 'v1/auth/emailverification/verify',
    data: body,
    headers: commonHeaders,
  }),

  login: (body) => ({
    method: 'POST',
    url: '/v1/auth/signin-with-email-pass',
    data: body,
    headers: commonHeaders,
  }),
  userEmailByUsername: (body) => ({
    method: 'POST',
    url: '/v1/auth/get-user-email-by-username',
    data: body,
    headers: commonHeaders,
  }),
  deleteAccount: (body) => ({
    method: 'POST',
    url: 'v1/user/delete-account-from-web',
    data: body,
    headers: commonHeaders,
  }),
};

export const clubs = {
  getClub: (payload) => ({
    method: 'POST',
    url: `/api/v1/clubs/clubInfo/${payload?.params?.clubId}`,
    headers: commonHeaders,
    data: payload?.body,
  }),
  favClub: (body) => ({
    method: 'POST',
    url: '/api/v1/clubs/favClub',
    headers: commonHeaders,
    data: body,
  }),
};

export const booking = {
  getAvailableProducts: (body) => ({
    method: 'GET',
    url: `/api/v1/clubs/availableProducts/${body.clubId}?date=${body.date}`,
    headers: commonHeaders,
  }),

  getAvailableSlots: (body) => ({
    method: 'GET',
    url: `/api/v1/clubs/AvailableSlots/${body?.id}/${body?.productId}?date=${body?.date}`,
    headers: commonHeaders,
  }),

  createBooking: (payload) => ({
    method: 'POST',
    url: `/api/v1/booking/create/Ontee/${payload?.params?.clubId}`,
    data: payload?.body,
    headers: commonHeaders,
  }),

  sendBookingEmail: (body) => ({
    method: 'POST',
    url: '/api/v1/bookings/sendEmail',
    headers: commonHeaders,
    data: body,
  }),
  getBooking: (payload) => ({
    method: 'GET',
    url: `/api/v1/clubBookings/${payload?.clubId}/bookings?date=${payload?.queryParams}`,
    headers: commonHeaders,
  }),
};

export const ontees = {
  getActiveGolfClubs: (body) => ({
    method: 'GET',
    url: '/api/v1/ontees/availableProducts',
    headers: commonHeaders,
  }),

  getClubsAvailableTime: (body) => ({
    method: 'POST',
    url: '/api/v1/ontees/getSlots',
    headers: commonHeaders,
    data: body,
  }),
};

export const racqys = {
  fetchRacqys: ({ page, sports, userLocation }) => ({
    method: 'GET',
    url: `/v3/racqys?page=${page}&sport=${sports.join(
      ','
    )}&filter=latest&useLocation=true&lat=${userLocation.latitude}&lng=${
      userLocation.longitude
    }`,
    headers: commonHeaders,
  }),
  fetchRacqy: (racqyId, gpsLocation) => ({
    method: 'POST',
    url: `/v2/racqy/share/${racqyId}`,
    headers: commonHeaders,
    data: gpsLocation,
  }),
};

export const users = {
  getUserById: (username) => ({
    method: 'GET',
    url: `/v2/racqy/getUserId/${username}`,
    headers: commonHeaders,
  }),
  getUserDetail: (userId) => ({
    method: 'GET',
    url: `/v2/user/${userId}/profile`,
    headers: commonHeaders,
  }),
  getUserInterests: (userId, body) => ({
    method: 'POST',
    url: `/v2/racqy/getUserInterests/${userId}`,
    headers: commonHeaders,
    data: body,
  }),
  searchFriends: (query = '', profileId, page = 0, mutualFriends = false) => ({
    method: 'GET',
    url: `/v2/user/friends/${profileId}/search?query=${query}&page=${page}&mutualFriends=${mutualFriends}`,
    headers: commonHeaders,
  }),
};
