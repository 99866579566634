import React, { useEffect } from 'react';

const RouteWrapper = ({ element, location }) => {
  //   useEffect(() => {
  //     console.log('element useEffect');

  //     const bgLoader = document.querySelector('.bg-loader');

  //     if (bgLoader?.classList.contains('hidden')) {
  //       bgLoader?.classList?.remove('hidden');
  //     }
  //   }, [location?.pathname]);

  return <>{element}</>;
};

export default RouteWrapper;
