import { initializeApp } from 'firebase/app';
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDyBbWAhwblAD75GfkJTCYi18Z25LpITdY',
  authDomain: 'racqy-132b6.firebaseapp.com',
  projectId: 'racqy-132b6',
  storageBucket: 'racqy-132b6.appspot.com',
  messagingSenderId: '700662615630',
  appId: '1:700662615630:web:8b88c18d37251e8ea6bd1f',
  measurementId: 'G-TR17BH5QT5',
};

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig);
// export const analytics = getAnalytics(firebase);
export const auth = getAuth(firebase);
export const functions = getFunctions(firebase, 'europe-west1');

if (process.env.NODE_ENV !== 'production') {
  // Connect to firebase emulator for function-invocations
  connectFunctionsEmulator(functions, 'localhost', 5001);
}
