import React from 'react';
import { AuthContextProvider } from './src/contexts/auth';
import { Provider } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { persistStore } from 'redux-persist';
// import createStore from "./src/redux/store/index"
import { persistor, store, createStore } from './src/redux/store/index';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopLoader from './src/components/TopLoader';
import RouteWrapper from './RouteWrapper';
import { Router } from '@reach/router';
// eslint-disable-next-line react/display-name,react/prop-types
const WrapWithProvider = ({ element }) => {
  const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  // const store = createStore()
  // Path to your Redux store
  //---------------------for removing persistance-----------------------------
  persistStore(createStore).purge();
  //---------------------for removing persistance-----------------------------
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_SITE_KEY}>
      <Elements stripe={stripePromise}>
        <Provider store={createStore}>
          <PersistGate loading={null} persistor={persistor}>
            <ToastContainer />
            <TopLoader />

            <AuthContextProvider>
              <Router>
                <RouteWrapper path="/*" element={element} />
              </Router>
            </AuthContextProvider>
          </PersistGate>
        </Provider>
      </Elements>
    </GoogleReCaptchaProvider>
  );
};

export default WrapWithProvider;
