import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import bookingReducer from '../slices/bookingSlice';
import onteesReducer from '../slices/onteesSlice';
import loadingReducer from '../slices/loaderSlice';
import storage from './storage';
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['onteesReducer', 'bookingReducer', 'loadingReducer'],
};

let reducers = combineReducers({
  bookingReducer,
  onteesReducer,
  loadingReducer,
  // devTools: process.env.NODE_ENV !== 'production',
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const createStore = configureStore({ reducer: persistedReducer });
export const persistor = persistStore(createStore);
